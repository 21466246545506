import ClassStringBuilder from 'core/utilities/class-string-builder';
import { ISharedTabButton } from './tab-button-interface';

const SharedTabButton = ({ changeTab, tab, currentTab }: ISharedTabButton) => {
  const active = tab.key === currentTab;
  const tabClasses = [
    'pb-3 mr-10 last:mr-0 border-b cursor-pointer hover:border-secondary transition',
    active ? 'border-primary' : 'border-transparent',
  ];

  return (
    <div className={ClassStringBuilder(tabClasses)} onClick={() => changeTab(tab.key)}>
      <p>{tab.label}</p>
    </div>
  );
};

export default SharedTabButton;
