export const ErrorMappingHelper = (error: Error, defaultMessage: string) => {
  switch (error.message) {
    case 'Firebase: Error (auth/user-not-found).':
      return 'Incorrect email or password';
    case 'Firebase: Error (auth/wrong-password).':
      return 'Incorrect password';
    case 'Passwords do not match':
      return error.message;
    default:
      return defaultMessage;
  }
};
