import { useState } from 'react';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { Eye, EyeOff } from 'react-feather';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';

interface IAddUserInfoDialog {
  fullName: string;
  emailAddress: string;
  temporaryPassword: string;
}

const AddUserInfoDialog = ({ fullName, emailAddress, temporaryPassword }: IAddUserInfoDialog) => {
  // Local State
  const [showPassword, setShowPassword] = useState(false);

  // Hooks
  const dialog = useDialog();

  const password = showPassword
    ? temporaryPassword
    : temporaryPassword
        .split('')
        .map(() => '•')
        .join('');

  const copyPassword = () => navigator.clipboard.writeText(temporaryPassword);

  const customContent = () => {
    return (
      <div className='body-02 p-4'>
        <p className='mb-4'>
          A new user account has been created for <b>{fullName}</b>. The credentials below can be used to access their
          account. They'll be asked to set their own password on first log in.
        </p>

        <p>Email: {emailAddress}</p>
        <div className='flex items-center'>
          <p> Password: {password}</p>
          <div
            className='flex items-center justify-center w-10 text-gray-400 hover:text-gray-700 cursor-pointer'
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
          </div>
        </div>
      </div>
    );
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'copyPassword',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => copyPassword(),
        appearance: 'subtle',
        label: 'Copy Password',
        type: 'button',
      },
    },
    {
      key: 'done',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'primary',
        label: 'Done',
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase title='User Created' customContentTemplate={customContent()} customButtons={customButtons} />
  );
};

export default AddUserInfoDialog;
