import { Route, Routes } from 'react-router-dom';
import ForgotPasswordForm from './forgot-password-form/forgot-password-form';
import ResetPasswordForm from './reset-password-form/reset-password-form';

const ForgotPasswordRouting = () => {
  return (
    <Routes>
      <Route path='' element={<ForgotPasswordForm />} />
      <Route path='/reset' element={<ResetPasswordForm />} />
    </Routes>
  );
};

export default ForgotPasswordRouting;
