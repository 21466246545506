import Button from '@atlaskit/button';
import { Color } from 'core/constants/colors';
import { IButton } from './button-interface';

const SharedButton = ({
  onClick,
  fitContainer = false,
  type,
  appearance,
  label,
  spacing,
  disabled = false,
}: IButton) => (
  <Button
    onClick={onClick}
    shouldFitContainer={fitContainer}
    style={{ ...(appearance === 'primary' && { backgroundColor: Color.PRIMARY }) }}
    type={type}
    appearance={appearance}
    spacing={spacing}
    isDisabled={disabled}
  >
    {label}
  </Button>
);

export default SharedButton;
