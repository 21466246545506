import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { IUser } from 'core/api/users/users-api-interface';
import UsersApiService from 'core/api/users/users-api.service';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';

interface IDeleteUserDialog {
  user: IUser;
}

const DeleteUserDialog = ({ user }: IDeleteUserDialog) => {
  const [loading, setLoading] = useState(false);
  // Hooks
  const dialog = useDialog();
  const flags = useFlags();

  const deleteUser = async () => {
    setLoading(true);
    try {
      await UsersApiService.remove(user.uid);
      showSuccessFlag('Account deleted', `${user.fullName}'s account was successfully deleted.`, flags);
      dialog?.closeDialog();
    } catch (error) {
      setLoading(false);
      showErrorFlag('Account deletion failed', `Unable to delete ${user.fullName}'s account, please try again.`, flags);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'subtle',
        label: 'Cancel',
        type: 'button',
      },
    },
    {
      key: 'deleteUser',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading,
        onClick: () => deleteUser(),
        appearance: 'danger',
        label: 'Delete User',
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={`Delete ${user.fullName}?`}
      textContent='Are you sure you want to delete this user? This action cannot be undone.'
      customButtons={customButtons}
    />
  );
};

export default DeleteUserDialog;
